import React from "react";
import { Card } from "primereact/card";
import { TableType } from "core/tables";
import styles from './TableRowTemplate.module.scss';

interface TableRowTemplateProps {
    title: string;
    icon: string;
    tableType: TableType;
    onClick: (tableType: TableType) => void
}

export const TableRowTemplate: React.FC<TableRowTemplateProps> = ({title, icon, tableType, onClick}) => {
    return (
        <Card className={styles.sidebarCard} onClick={() => onClick(tableType)}>
            <div className={styles.content}>
                <i className={`${icon} ${styles.icon}`}></i>
                <span>{title}</span>
            </div>
        </Card>
    );
};
