import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Layer, Source } from "@urbica/react-map-gl";
import { areaStore } from "stores/AreaStore";
import { Feature, Geometry } from "geojson";
import { strongholdsController } from "./Strongholds.controller";

const STRONG_HOLD_PAINT_STYLE = {
    'line-color': '#000000',
    'line-width': 3,
};

export const Strongholds = observer(() => {
    const {areas} = areaStore;

    const strongHolds = useMemo(() =>
            areas.flatMap(area =>
                area.geoJson.features.flatMap((feature): Feature<Geometry>[] => {
                    if (
                        feature.type === "Feature" &&
                        (feature.geometry.type === 'LineString' || feature.geometry.type === 'MultiLineString')
                    ) {
                        if (area.id && feature.properties) feature.properties.isVisible = strongholdsController.getLocalStorageAreaVisibleById(area.id)
                        return [feature]; // Возвращаем массив с одним элементом, если условие выполнено
                    }
                    return []; // Возвращаем пустой массив, если условие не выполнено
                })
            ),
        [areas]
    );

    if (strongHolds) {
        return (
            <div>
                <Source
                    id='strongholds'
                    type='geojson'
                    data={{
                        type: 'FeatureCollection',
                        features: strongHolds,
                    }}
                />
                <Layer
                    id='strongholds'
                    type='line'
                    before='units'
                    source='strongholds'
                    filter={['!=', ['get', 'isVisible'], false]}
                    paint={STRONG_HOLD_PAINT_STYLE}
                />
            </div>
        );
    }

    return null;
})
