import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Card } from "primereact/card";
import { IDrawWithId } from "services/AreaService";
import { Button } from "primereact/button";
import { areaStore, mapStore, modalStore } from "stores";
import { ModalType } from "core/modals";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import { areaNoteTemplateController } from "./AreaNoteTemplate.controller";
import RightContext from "contexts/AppRightsProvider";
import { areaActionTypes } from "components/Modals/CreateModal/CreateModal";
import { getRights } from "utils";
import styles from './AreaNoteTemplate.module.scss';
import { ConfirmDialog } from "primereact/confirmdialog";

interface EventTemplateProps {
    area: IDrawWithId;
}

const DEFAULT_EDIT_AREA_ID = undefined;

export const AreaNoteTemplate = observer(({area}: EventTemplateProps) => {

    const {rights} = useContext(RightContext);
    const {editAreaId} = areaStore;
    const {isToday} = mapStore;
    const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
    const allIds: string[] = useMemo(() => {
        return area.geoJson.features.flatMap(feature => {
            if (feature.type === "Feature" && feature.geometry?.type !== 'Point' && typeof feature.id === 'string') {
                return feature.id;
            }
            return [];
        });
    }, [area]);

    const areaControllerRights = useMemo(() => getRights(rights, 'areaController'), [rights]);
    const isEditable = useMemo(() => area.geoJson.features.length > 0, [area]);
    const isRemovable = useMemo(() => area.geoJson.features.every(feature => feature.properties?.isRemovable) || area.geoJson.features.length === 0, [area]);
    const isVisible = useMemo(() => {
        if (area.id) {
            return areaNoteTemplateController.getLocalStorageAreaVisibleById(area.id);
        }
        return area.geoJson.features.every(feature => feature.properties?.isVisible === undefined || feature.properties?.isVisible)
    }, [area]);
    const [isChecked, setIsChecked] = useState<boolean>(isVisible);

    const handleEditClick = useCallback(() => {
        areaNoteTemplateController.setAreaToDrawData(area)
        areaNoteTemplateController.setDrawMode('simple_select')
        areaNoteTemplateController.setEditAreaId(area.id)
        areaNoteTemplateController.setAllIds(allIds)
    }, [area, allIds])

    const handleOnCardClick = useCallback(() => {
        if (areaControllerRights && !editAreaId) modalStore.open({
            type: ModalType.AreaInfo,
            areaData: area,
            actionType: areaActionTypes.UPDATE_AREA,
        })
        return
    }, [area, areaControllerRights, editAreaId])

    const handleOnInputSwitchChange = useCallback((e: InputSwitchChangeEvent) => {
        e.stopPropagation()
        if (area.id) {
            areaNoteTemplateController.setAreaVisible(!!e.value, area.id)
            areaNoteTemplateController.setAreaVisibleToLocalStorageById(area.id, !!e.value)
        }
        if (!e.value) {
            areaNoteTemplateController.setEditAreaId(DEFAULT_EDIT_AREA_ID)
            areaNoteTemplateController.removeAreasFromDrawData(allIds);
            areaNoteTemplateController.setAllIds(undefined)
        }

        setIsChecked(!!e.value)
        return
    }, [area.id, allIds])

    const handleSaveClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        areaNoteTemplateController.updateArea(area);
        areaNoteTemplateController.setEditAreaId(DEFAULT_EDIT_AREA_ID);
        areaNoteTemplateController.setAllIds(undefined)
        return
    }, [area]);

    const handleCancelClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        areaNoteTemplateController.removeAreasFromDrawData(allIds);
        areaNoteTemplateController.setEditAreaId(DEFAULT_EDIT_AREA_ID);
        areaNoteTemplateController.setAllIds(undefined)
        return
    }, [allIds]);

    const handlePencilClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (areaControllerRights) return handleEditClick();
    }, [areaControllerRights, handleEditClick]);

    const handleDeleteClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (areaControllerRights) return setShowDeleteConfirm(true);
    }, [areaControllerRights]);

    const confirmDelete = useCallback(() => {
        if (area.id) areaNoteTemplateController.deleteArea(area.id);
        setShowDeleteConfirm(false);
    }, [area.id]);

    const cancelDelete = useCallback(() => {
        setShowDeleteConfirm(false);
    }, []);

    const isDisabled = useMemo(() => {
        return !!editAreaId || !isChecked || !isToday
    }, [editAreaId, isChecked, isToday])

    useEffect(() => {
        if (!isToday) {
            areaNoteTemplateController.setEditAreaId(DEFAULT_EDIT_AREA_ID);
        }
    }, [isToday])

    return <div
        className={styles.cardLayout}
        onClick={handleOnCardClick}
    >
        <Card
            subTitle={areaControllerRights ? `id: ${area?.id}` : ''}
            pt={{
                body: {className: styles.body}
            }}
            style={{position: 'relative', marginBottom: '2rem'}}
        > {
            areaControllerRights && (
                <>
                    {isToday && area.id === editAreaId ? (
                        <>
                            <Button icon="pi pi-check" className="p-button-rounded p-button-success"
                                    style={{position: 'absolute', top: '0.5rem', right: '0.5rem'}}
                                    onClick={handleSaveClick}/>
                            <Button icon="pi pi-times" className="p-button-rounded p-button-danger"
                                    style={{position: 'absolute', top: '0.5rem', right: '4rem'}}
                                    onClick={handleCancelClick}/>
                        </>
                    ) : (
                        <>
                            {isEditable && <Button
                              icon="pi pi-pencil"
                              className="p-button-rounded p-button-success"
                              style={{position: 'absolute', top: '0.5rem', right: '0.5rem'}}
                              onClick={handlePencilClick}
                              disabled={isDisabled}
                            />}
                            {isRemovable && <Button
                              icon="pi pi-trash"
                              className="p-button-rounded p-button-danger"
                              style={{position: 'absolute', top: '0.5rem', right: '4rem'}}
                              onClick={handleDeleteClick}
                              disabled={isDisabled}
                            />}
                        </>
                    )}
                </>)}
            <InputSwitch
                checked={isChecked}
                onChange={handleOnInputSwitchChange}
                style={{position: 'absolute', bottom: '0.5rem', right: '0.5rem'}}
            />
            <div className={styles.cardContentLayout}>
                <div className={styles.text}>{area?.title}</div>
            </div>
        </Card>
        {areaControllerRights && <ConfirmDialog
          visible={showDeleteConfirm}
          onHide={cancelDelete}
          message="Are you sure you want to delete this area?"
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          accept={confirmDelete}
          reject={cancelDelete}
        />}
    </div>
})
