import { IMarker } from "./types";
import { IDraw } from "../services/AreaService";

export enum ModalType {
    CreateMarker = 'CreateMarker',
    EditMarker = 'EditMarker',
    CreateCountry = 'CreateCountry',
    CreateMarkerTypes = 'CreateMarkerTypes',
    AccountInfo = 'AccountInfo',
    AreaInfo = 'AreaInfo',
    AddFeatureToArea = 'AddFeatureToArea',
}

interface BaseModalProps {
    onClose?: () => void;
    actionType: actionTypes | undefined;
}

interface CreateMarkerModalProps extends BaseModalProps {
    type: ModalType.CreateMarker;
    markerData?: IMarker;
}

interface EditMarkerModalProps extends BaseModalProps {
    type: ModalType.EditMarker;
    markerData?: IMarker;
}

interface CreateCountryModalProps extends BaseModalProps {
    type: ModalType.CreateCountry;
}

interface CreateMarkerTypeModalProps extends BaseModalProps {
    type: ModalType.CreateMarkerTypes;
}

interface AccountInfoModalProps extends BaseModalProps {
    type: ModalType.AccountInfo;
}

interface AreaInfoModalProps extends BaseModalProps {
    type: ModalType.AreaInfo;
    areaData?: IDraw;
}

interface AddFeatureToArea extends BaseModalProps {
    type: ModalType.AddFeatureToArea;
}

export type actionTypes = markerActionTypes | areaActionType

export type markerActionTypes = 'CREATE_MARKER' | 'MOVE_MARKER' | 'COPY_MARKER' | 'EDIT_MARKER'

export type areaActionType = 'CREATE_AREA' | 'UPDATE_AREA' | 'ADD_AREA'

export type ModalProps =
    CreateMarkerModalProps
    | EditMarkerModalProps
    | CreateCountryModalProps
    | CreateMarkerTypeModalProps
    | AccountInfoModalProps
    | AreaInfoModalProps
    | AddFeatureToArea
