import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { Dropdown } from "primereact/dropdown";
import { Formik } from "formik";
import * as Yup from "yup";
import { areaStore, drawStore, modalStore } from "stores";
import { addFeatureToAreaModalController } from "./AddFeatureToAreaModal.controller";
import { ModalType } from "core/modals";
import { ModalContainer } from "../";
import styles from "./AddFeatureToAreaModal.module.scss";

interface Props {
    type:  ModalType | null;
    title: string;
    onClose?: (() => void);
}

const validationSchema = Yup.object().shape({
    featureId: Yup.number().required('Please select an Area')
});

export const AddFeatureToAreaModal = observer(({type,title, onClose}: Props) => {

    const {areas} = areaStore;
    const {areaToSave} = drawStore;
    const areasOptions = useMemo(() => areas.filter(area => area.geoJson.features
            .every(feature => feature.properties?.isCollection)
        || area.geoJson.features.length === 0).map(area => {
        return {
            label: area.title,
            value: area.id,
        }
    }), [areas])

    const onModalCloseHandler = useCallback(() => {
        modalStore.close();
        if (onClose) onClose();
    }, [onClose]);

    return (
        <ModalContainer
            type={type}
            title={title}
            onClose={onModalCloseHandler}
        >
            <Formik
                initialValues={{featureId: null}}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    const areaToUpdate = areas.find(area => area.id === values.featureId)
                    if (areaToUpdate && areaToSave) addFeatureToAreaModalController.updateAreaAPIRequest(areaToUpdate, [areaToSave]).then(res => {
                        if (res) {
                            addFeatureToAreaModalController.updateAreaInStore(res.data)
                            addFeatureToAreaModalController.refreshSnapshot()
                            if (typeof areaToSave.id === "string") {
                                addFeatureToAreaModalController.removeFeatureFromDrawData(areaToSave.id);
                                addFeatureToAreaModalController.removeAreaToSave(areaToSave.id);
                            }
                        }
                    })
                    modalStore.close()
                }}
            >
                {({
                      values,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      errors,
                      touched
                  }) => (
                    <form className={styles.form} onSubmit={handleSubmit}>
                        <Dropdown
                            value={values.featureId}
                            options={areasOptions}
                            onChange={(e) => setFieldValue('featureId', e.value)}
                            placeholder="Select a Feature"
                            className={styles.dropdown}
                        />
                        {errors.featureId && touched.featureId && (
                            <div className={styles.errors}>{errors.featureId}</div>
                        )}
                        <button className={styles.button} type="submit" disabled={isSubmitting}>
                            Add Feature To Selected Area
                        </button>
                    </form>
                )}
            </Formik>
        </ModalContainer>
    )
})
