import { routes } from "core/navigation";
import { Button } from "primereact/button";
import { NavLink } from "react-router-dom";
import React from "react";

interface SignInNavLinkProps {
    classname?: string;
}

export const SignInNavLink = ({classname}: SignInNavLinkProps) => {
    return <NavLink to={routes.login.path}>
        <Button label="Sign In" className={classname}/>
    </NavLink>
}
