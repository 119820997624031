import { Rights } from "core/types";

export const getRights = (role: Rights, field: string): boolean => {
    if (role === RIGHTS_NAMES.USER || role === RIGHTS_NAMES.CREATOR || role === RIGHTS_NAMES.PRO || RIGHTS_NAMES.ANONYMOUS || role === RIGHTS_NAMES.ADMIN) return (RIGHTS as any)[role][field]
    return false
}

export const RIGHTS_TYPES = {
    SIGN_IN: 'sign_in',
    COORDINATES_BUTTON: 'coordinatesButton',
    TABLES_BUTTON: 'tablesButton',
    DISABLED_TABLE_BUTTONS: 'disabledTableButtons',
    CHANGE_MAP: 'changeMap',
    EDIT_MARKER: 'editMarker',
    HIDE_GOOGLE_ADSENSE: 'hideGoogleAdSense',
    AREA_CONTROLLER: 'areaController',
    CHANGE_DATE: 'changeDate',
    SHOW_VIDEO: 'showVideo'
}

export const RIGHTS_NAMES = {
    ADMIN:'admin' as Rights,
    USER:'user' as Rights,
    PRO:'pro' as Rights,
    ANONYMOUS:'anonymous'as Rights,
    CREATOR:'creator' as Rights,
}

const RIGHTS = {
    anonymous: {
        sign_in: true,
        coordinatesButton: false,
        tablesButton: false,
        disabledTableButtons: true,
        changeMap: false,
        editMarker: false,
        hideGoogleAdSense: false,
        areaController: false,
        changeDate: false,
        showVideo: false,
    },
    user: {
        sign_in: false,
        coordinatesButton: false,
        tablesButton: false,
        disabledTableButtons: true,
        changeMap: false,
        editMarker: false,
        hideGoogleAdSense: false,
        areaController: false,
        changeDate: false,
        showVideo: false,
    },
    creator: {
        sign_in: false,
        coordinatesButton: true,
        tablesButton: true,
        disabledTableButtons: true,
        changeMap: false,
        editMarker: true,
        hideGoogleAdSense: true,
        areaController: false,
        changeDate: true,
        showVideo: true,
    },
    pro: {
        sign_in: false,
        coordinatesButton: false,
        tablesButton: false,
        disabledTableButtons: true,
        changeMap: false,
        editMarker: false,
        hideGoogleAdSense: true,
        areaController: false,
        changeDate: true,
        showVideo: true,
    },
    admin: {
        sign_in: false,
        coordinatesButton: true,
        tablesButton: true,
        disabledTableButtons: false,
        changeMap: true,
        editMarker: true,
        hideGoogleAdSense: true,
        areaController: true,
        changeDate: true,
        showVideo: true,
    },
}
