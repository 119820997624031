import React from "react";
import { Dialog } from "primereact/dialog";
import { ModalType } from "core/modals";

interface ModalContainerProps {
    type:  ModalType | null;
    title?: string;
    children: React.ReactNode;
    onClose: () => void;
}


export const ModalContainer: React.FC<ModalContainerProps> = React.memo(({type, title, children, onClose}) => {
    return (
        <Dialog
            header={title}
            visible={!!type}
            onHide={() => {if (!type) return; onClose(); }}
        >
            {children}
        </Dialog>
    )
})
