import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Nullable } from "primereact/ts-helpers";
import moment from "moment";
import {
    calendarRefreshComponentController,
} from "./CalendarRefreshComponent.controller";
import { mapStore } from "stores";
import { CalendarComponent } from "./CalendarComponent/CalendarComponent";
import { RefreshButton } from "./RefreshButton/RefreshButton";

interface CalendarRefreshComponentProps {
    calendarClassName: string,
    refreshButtonClassName: string,
    eventsMarkerTypeId: number,
    typeIds: number[];
}

const SNAPSHOT_BEGINNING = moment('2024-09-27');

export const CalendarRefreshComponent = observer(({
                                                      calendarClassName,
                                                      refreshButtonClassName,
                                                      eventsMarkerTypeId,
                                                      typeIds
                                                  }: CalendarRefreshComponentProps) => {

    const mapInstance = mapStore.mapInstance;
    const [dates, setDates] = useState<Nullable<Date[]>>(
        [new Date(moment().startOf('day').toISOString()),
            new Date(moment().endOf('day').toISOString())]
    )
    const [isSpinning, setIsSpinning] = useState(false);

    const isRangeSelected = useMemo(() => {
        if (!dates || dates.length !== 2) return false;
        const [startDate, endDate] = dates;
        if (!endDate) return true
        return (
            startDate.getFullYear() !== endDate.getFullYear() ||
            startDate.getMonth() !== endDate.getMonth() ||
            startDate.getDate() !== endDate.getDate()
        );
    }, [dates]);

    const refreshData = useCallback(() => {
        setIsSpinning(true);
        if (dates) Promise.all([
            calendarRefreshComponentController.init(typeIds),
            calendarRefreshComponentController.getEvents(dates, eventsMarkerTypeId)
        ]).finally(() => setIsSpinning(false));
    }, [dates, eventsMarkerTypeId, typeIds]);

    useEffect(() => {
        if (mapInstance) calendarRefreshComponentController.getEvents(dates, eventsMarkerTypeId)
        const isDateAfterSnapshotBeginning = dates?.every(date =>
            moment(date).isSameOrAfter(SNAPSHOT_BEGINNING, 'day')
        );

        if (!isRangeSelected && isDateAfterSnapshotBeginning) {
            calendarRefreshComponentController.getSnapShots(dates);
        } else if (isRangeSelected) {
            calendarRefreshComponentController.resetSnapShots();
        }
    }, [dates, eventsMarkerTypeId, mapInstance, isRangeSelected])

    return (
        <>
            <CalendarComponent
                dates={dates}
                className={calendarClassName}
                isSpinning={isSpinning}
                setDates={setDates}
                isRangeSelected={isRangeSelected}
            />
            <RefreshButton
                isSpinning={isSpinning}
                className={refreshButtonClassName}
                refreshData={refreshData}
                setIsSpinning={setIsSpinning}
            />
        </>
    )
})
