import React from "react";
import { Rights } from "core/types";
import { getRights } from "utils";
import { CloseIcon } from "assets/icons/icons";
import styles from "../Popup.module.scss";
import { RIGHTS_TYPES } from "../../../utils/getRights";

interface IPopupLayoutProps {
    children?: React.ReactNode,
    className: string,
    rights: Rights
    handleOnCloseClick: () => void,
    handleOnPlayClick?: () => void,
    handleOnEditClick: () => void,
    handleOnCopyClick?: () => void,
    handleOnMoveClick?: () => void,
    handleOnCopyToClick?: () => void,
}

export const PopupLayout = React.memo(({
                                           children,
                                           className,
                                           rights,
                                           handleOnCloseClick,
                                           handleOnPlayClick,
                                           handleOnEditClick,
                                           handleOnCopyClick,
                                           handleOnMoveClick,
                                           handleOnCopyToClick
                                       }: IPopupLayoutProps) => {
    return (<div className={className}>
        <button className={styles.closeButton} onClick={handleOnCloseClick}><CloseIcon/></button>
        {children}
        <div className={styles.controlsLayout}>
            {getRights(rights, RIGHTS_TYPES.EDIT_MARKER) && (<>
                <button className={styles.button} onClick={handleOnEditClick}>Edit</button>
                {handleOnCopyClick && <button className={styles.button} onClick={handleOnCopyClick}>Copy</button>}
                {handleOnMoveClick && <button className={styles.button} onClick={handleOnMoveClick}>Move</button>}
                {handleOnCopyToClick &&
                  <button className={styles.button} onClick={handleOnCopyToClick}>Copy To</button>}
            </>)}
            {handleOnPlayClick && <button className={styles.button} onClick={handleOnPlayClick}>Play</button>}
        </div>
    </div>)
})
