import { makeAutoObservable, runInAction } from 'mobx';

export interface IAreaConfig {
    id: string,
    isVisible: boolean,
}

const LOCAL_STORAGE = {
    ACCESS_TOKEN: 'accessToken',
    REFRESH_TOKEN: 'refreshToken',
    AREAS_CONFIG: 'areasConfig',
}

export class LocalStorageService {
    localStorageAccessToken: string | null;
    localStorageRefreshToken: string | null;
    areasConfig: string | null;

    localStorage: Storage;

    constructor(localStorage: Storage) {
        makeAutoObservable(this)

        this.localStorage = localStorage;
        this.localStorageAccessToken = this.getLocalStorageAccessToken();
        this.localStorageRefreshToken = this.getLocalStorageRefreshToken();
        this.areasConfig = this.getAreasConfig();
        this.getLocalStorageAccessToken = this.getLocalStorageAccessToken.bind(this);
        this.getLocalStorageRefreshToken = this.getLocalStorageRefreshToken.bind(this);
    }

    getLocalStorageAccessToken() {
        return this.localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    }

    getLocalStorageRefreshToken() {
        return this.localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN);
    }

    getAreasConfig() {
        return this.localStorage.getItem(LOCAL_STORAGE.AREAS_CONFIG);
    }

    setLocalStorageAccessToken(value: string) {
        runInAction(() => this.localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, value));
    }

    setLocalStorageRefreshToken(value: string) {
        runInAction(() => this.localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, value));
    }

    getLocalStorageAreaVisibleById(id: number) {
        if (this.areasConfig) {
            let config = JSON.parse(this.areasConfig).find((areaConfig: IAreaConfig) => parseInt(areaConfig.id) === id)
            if (config) return config.isVisible
            return true
        }
        return true
    }

    setAreaVisibleToLocalStorageById(id: number, isVisible: boolean) {
        runInAction(() => {
            if (this.areasConfig) {
                const config = JSON.parse(this.areasConfig);
                if (typeof config === 'object') {
                    const areaConfig = config.find((config: IAreaConfig) => parseInt(config.id) === id);
                    if (areaConfig) {
                        this.localStorage.setItem(LOCAL_STORAGE.AREAS_CONFIG, JSON.stringify(config.map((config: IAreaConfig) => {
                            if (parseInt(config.id) === id) return {
                                id, isVisible
                            }
                            return config
                        })))
                        this.areasConfig = this.getAreasConfig();
                        return;
                    }

                    if (!areaConfig) {
                        let newConfig = config.concat({id, isVisible})
                        this.localStorage.setItem(LOCAL_STORAGE.AREAS_CONFIG, JSON.stringify(newConfig))
                        this.areasConfig = this.getAreasConfig();
                        return;
                    }
                }
                return;
            }

            if (!this.areasConfig) {
                this.localStorage.setItem(LOCAL_STORAGE.AREAS_CONFIG, JSON.stringify([{
                    id, isVisible
                }]))
                this.areasConfig = this.getAreasConfig();
                return;
            }
        });
    }

}

export const localStorageService = new LocalStorageService(localStorage);
