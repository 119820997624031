import React, { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { DataScroller } from "primereact/datascroller";
import { TableType } from "core/tables";
import { IStructure, TABLE_STRUCTURES } from "utils/getTablesStructure";
import { TableRowTemplate } from "./TableRowTemplate/TableRowTemplate";
import styles from './TablesSideBarLayout.module.scss';

interface TablesSideBarLayoutProps {
    onClick: (tableType: TableType) => void
}

export const TablesSideBarLayout = ({onClick}:TablesSideBarLayoutProps) => {
    const [visible, setVisible] = useState<boolean>(false)

    const itemTemplate = (data: IStructure) => {

        return <TableRowTemplate
            title={data.tableTitle}
            icon={data.icon}
            tableType={data.tableType}
            onClick={onClick}
        />
    }

    return (
        <div className={styles.layout}>
            <Sidebar
                position={"left"}
                visible={visible}
                dismissable={false}
                modal={false}
                onHide={() => setVisible(false)}>
                <DataScroller value={TABLE_STRUCTURES} itemTemplate={itemTemplate} rows={TABLE_STRUCTURES.length}
                              buffer={0.4}/>
            </Sidebar>
            <Button icon="pi pi-database" onClick={() => setVisible(true)}/>
        </div>
    )
}
