import React from "react";
import { UnitTypes } from "core/types";
import styles from "../../Marker.module.scss";

export interface LeaderWarriorComponentProps {
    icon: string | UnitTypes,
    markerTypeTitle: string,
    titleNative: string,
    imageClasses: string,
}

export const LeaderWarriorComponent = React.memo(({
                                                      icon,
                                                      markerTypeTitle,
                                                      titleNative,
                                                      imageClasses
                                                  }: LeaderWarriorComponentProps) => {

    return (
        <div className={imageClasses}>
            <img className={styles.images_country} src={`flags/${titleNative}.png`} alt={titleNative}/>
            <div className={styles.iconImageWrapper}>
                {markerTypeTitle === 'Leader' &&
                  <img src={icon} alt={titleNative}/>}
                {markerTypeTitle === 'Warrior' &&
                  <img src={icon} alt={titleNative}/>}
            </div>
        </div>
    )
})
