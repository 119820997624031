import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { DataScroller } from "primereact/datascroller";
import { mapStore, markersStore } from "stores";
import { IMarker } from "core/types";
import { AccountInfoButton } from "../";
import { EventTemplate } from "./EventTemplate/EventTemplate";
import { DEVICE_TYPES, DeviceType } from "hooks/useDeviceType";
import styles from './SideBarLayout.module.scss';

interface SideBarLayoutProps {
    accountInfoClassName: string;
    onMarkerClick: (marker: IMarker | null | any) => void
    deviceType: DeviceType;
}

export const SideBarLayout = observer(({accountInfoClassName, deviceType, onMarkerClick}: SideBarLayoutProps) => {

    const {events} = markersStore;
    const [visible, setVisible] = useState<boolean>(false)

    const onSideBarItemClick = useCallback((marker: IMarker) => {
        mapStore.onMapMove({
            latitude: marker.coordinates.lat,
            longitude: marker.coordinates.lng,
            zoom: 11,
        })
        onMarkerClick(marker)
    }, [onMarkerClick])

    const eventTemplate = (data: IMarker) => {

        return <EventTemplate
            marker={data}
            onCLick={onSideBarItemClick}
            setSideBarVisible={deviceType === DEVICE_TYPES.DESKTOP ? undefined : setVisible}
        />
    }

    const reversedEvents = useMemo(() => {
        return computed(() => {
            const _sideBarEvents: IMarker[] = []
            events.forEach((event: IMarker) => {
                if (event) _sideBarEvents.unshift(event)
            })
            return _sideBarEvents
        })
    }, [events]).get()

    return <div className={styles.sideBarLayout}>
        <Sidebar
            position={"right"}
            visible={visible}
            dismissable={false}
            modal={false}
            onHide={() => setVisible(false)}>
            <AccountInfoButton
                className={accountInfoClassName}
                setSideBarVisible={deviceType === DEVICE_TYPES.DESKTOP ? undefined : setVisible}
            />
            <DataScroller value={reversedEvents} itemTemplate={eventTemplate} rows={events.length} buffer={0.4}/>
        </Sidebar>
        <Button icon="pi pi-arrow-left" onClick={() => setVisible(true)}/>
    </div>
})
