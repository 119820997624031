import { useCallback, useMemo } from "react";
import { observer } from 'mobx-react';
import { useAppStore } from "hooks/useAppStore";
import { ModalType } from "core/modals";
import { AccountInfoModal, AddFeatureToAreaModal, CreateMarkerModal, CreateModal } from "./";
import { getCreateModalsStructure } from "utils";
import { areaActionTypes } from "./CreateModal/CreateModal";

export const Modal = observer(() => {
    const {modalStore} = useAppStore();
    const {props} = modalStore;

    const getTitleByModalType = useCallback((modalType: ModalType) => {
        switch (modalType) {
            case ModalType.CreateMarkerTypes:
                return 'Create Marker Types'
            case ModalType.CreateMarker:
                return 'Create Marker'
            case ModalType.EditMarker:
                return 'Edit Marker'
            case ModalType.AreaInfo:
                return 'Area Info'
            case ModalType.CreateCountry:
                return 'Create Country'
            case ModalType.AccountInfo:
                return 'Account Info'
            case ModalType.AddFeatureToArea:
                return 'Add Feature To Area'
            default:
                return '';
        }
    }, []);

    const structure = useMemo(() => {
        if (props?.type) return getCreateModalsStructure(props.type)
        return undefined
    }, [props])

    if (!props?.type) {
        return <></>;
    }

    if (props.actionType === areaActionTypes.ADD_AREA) {
        return <AddFeatureToAreaModal
            type={props.type}
            title={getTitleByModalType(props.type)}
        />
    }

    if (structure) {
        return <CreateModal
            structure={structure}
            title={getTitleByModalType(props.type)}
            initialData={"areaData" in props ? props?.areaData : undefined}
            onClose={props?.onClose}
            {...props}
        />
    }

    if (props.type === ModalType.CreateMarker || props.type === ModalType.EditMarker) {
        return <CreateMarkerModal
            title={getTitleByModalType(props.type)}
            onClose={props.onClose}
            {...props}
        />;
    }

    if (props.type === ModalType.AccountInfo) {
        return <AccountInfoModal
            title={getTitleByModalType(props.type)}
            onClose={props.onClose}
            {...props}
        />;
    }

    return <></>;
});
