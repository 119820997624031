import moment from "moment/moment";
import { areaService, IDrawWithId } from "services/AreaService";
import { Feature } from "geojson";
import { drawService } from "services/DrawService";
import { snapShotService } from "services";

export class AddFeatureToAreaModalController {

    public async updateAreaAPIRequest(area: IDrawWithId, features: Feature[]) {
        const featureCollection = area.geoJson.features.concat(features)
        const _features = featureCollection.map(feature => {
            return {
                ...feature, properties: {
                    ...feature.properties,
                    isRemovable: true,
                    isCollection: true,
                }
            }
        })
        return await areaService.updateAreaAPIRequest(area, _features);
    }

    public updateAreaInStore(area: IDrawWithId) {
        areaService.updateAreaInStore(area);
    }

    public removeFeatureFromDrawData(id: string) {
        drawService.removeFeatureFromDrawData(id);
    }

    public removeAreaToSave(id: string) {
        drawService.removeAreaToSave(id);
    }

    public refreshSnapshot() {
        const date = new Date();
        snapShotService.getSnapShots(moment(date).startOf('day').format('YYYY-MM-DD'), moment(date).endOf('day').format('YYYY-MM-DD'))
    }
}

export const addFeatureToAreaModalController = new AddFeatureToAreaModalController();
