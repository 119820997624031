import { ApiConfig, apiService } from "./ApiService";
import { IDraw, IDrawWithId } from "./AreaService";
import { snapShotsStore } from "stores";

export class SnapShotService {

    public async getSnapShots(from: string, to: string) {
        Promise.all([
            apiService.axiosGetRequest(ApiConfig.SNAPSHOTS, undefined, {
                from, to
            })
        ])
            .then(res => res.map(i => i.data))
            .then(res => res.map((i, index) => {
                if (index === 0) {
                    return i[0];
                }
                return [i];
            }))
            .then(data => {
                if(data[0] && data[0].areas) return data[0].areas
                return
            })
            .then((snapShots: IDraw[]) => {
                if (snapShots?.length) this.setSnapShots(snapShots)
            });
    }

    public setSnapShots(snapShots: IDrawWithId[]) {
        snapShotsStore.setSnapShots(snapShots);
    }

    public resetSnapShots() {
        snapShotsStore.resetSnapShots();
    }
}

export const snapShotService = new SnapShotService();
