export type PopupTypes = "Default" | "Rumble" | "Telegram" | "X" | "RMP" | "Description" | "Leader" | "Unit"

export const popupTypes = {
    DEFAULT: "Default" as PopupTypes,
    RUMBLE: "Rumble" as PopupTypes,
    TELEGRAM: "Telegram" as PopupTypes,
    X: "X" as PopupTypes,
    RMP: "RMP" as PopupTypes,
    DESCRIPTION: "Description" as PopupTypes,
    LEADER: "Leader" as PopupTypes,
    UNIT: "Unit" as PopupTypes
}
