import { ICountryPayload, IMarkerPayload, IMarkerTypePayload } from "../core/types";
import axios, { AxiosRequestConfig } from "axios";
import { authStore } from "stores";
import { UserCredentials } from "./AuthService";
import { IAreaBase, IAreaPayload, IDraw } from "./AreaService";

let CORE_API_URL = '/api/v1.0';
axios.defaults.baseURL = 'https://dev.militarysummary.com';

export const ApiConfig = {
    ACCOUNT_REGISTER: `${CORE_API_URL}/account`,
    ACCOUNT_ACTIVATE: `${CORE_API_URL}/account/activate`,
    ACCOUNT: `${CORE_API_URL}/account`,
    AUTH_SIGN_IN: `${CORE_API_URL}/auth/login`,
    AUTH_LOG_OUT: `${CORE_API_URL}/auth/logout`,
    TOKEN_REFRESH: `${CORE_API_URL}/auth/refresh`,
    GROUPS: `${CORE_API_URL}/groups`,
    GROUP_COLORS: `${CORE_API_URL}/group-colors`,
    TASKS: `${CORE_API_URL}/tasks`,
    COUNTRY: `${CORE_API_URL}/country`,
    deleteCountry: (ids: string) => `${CORE_API_URL}/country/${ids}`,
    deleteCountries: () => `${CORE_API_URL}/country`,
    MARKER_TYPE: `${CORE_API_URL}/marker-type`,
    deleteMarkerType: (ids: string) => `${CORE_API_URL}/marker-type/${ids}`,
    deleteMarkerTypes: () => `${CORE_API_URL}/marker-type`,
    MARKER: `${CORE_API_URL}/marker`,
    updateMarker: (id: number) => `${CORE_API_URL}/marker/${id}`,
    deleteMarker: (ids: string) => `${CORE_API_URL}/marker/${ids}?withVideo=true&withImage=true`,
    deleteMarkers: () => `${CORE_API_URL}/marker?withVideo=true&withImage=true`,
    AREA: `${CORE_API_URL}/area`,
    SNAPSHOTS: `${CORE_API_URL}/area/snapshot`,
    updateArea: (id: number) => `${CORE_API_URL}/area/${id}`,
    createArea: () => `${CORE_API_URL}/area/`,
    deleteArea: (id: number) => `${CORE_API_URL}/area/${id}`,
    FILES: `${CORE_API_URL}/files`,
    UNLINKED_FILES: `${CORE_API_URL}/files/unlinked`,
    ME: `${CORE_API_URL}/account/me`,
};

export class ApiService {

    public async axiosPostRequest(url: string, payload: IMarkerPayload | UserCredentials | IDraw | ICountryPayload | IMarkerTypePayload ) {
        return await axios.post(url, payload, ApiService.postRequestOptions())
    }

    public async axiosPatchRequest(url: string, payload: IMarkerPayload | IAreaPayload | IAreaBase) {
        return await axios.patch(url, payload, ApiService.postRequestOptions())
    }

    public async axiosGetRequest(
        url: string,
        token: string | null = null,
        params: any | undefined = undefined) {
        if (token || params) return axios(url, ApiService.getRequestOptions({token, params}));
        return axios(url, ApiService.getRequestOptions({token: null, params: undefined}));
    }

    public async axiosDeleteRequest(url: string, data: number[] | string[] = []) {
        return await axios.delete(url, ApiService.deleteRequestOptions(data));
    }

    private static postRequestOptions(): AxiosRequestConfig {
        const {authState} = authStore;
        const headers: any = {};
        if (authState.accessToken) headers.Authorization = `Bearer ${authState.accessToken}`

        return {
            headers
        };
    }

    private static getRequestOptions({
                                         token,
                                         params,
                                     }: {
        token: string | null,
        params: any | undefined,
    }): AxiosRequestConfig {
        const {authState} = authStore;

        return {
            method: "GET",
            params: params,
            headers: {
                ContentType: "application/json",
                Authorization: token ? `Bearer ${token}` : `Bearer ${authState.accessToken}`
            },
        };
    }

    private static deleteRequestOptions(data: number[] | string[]): AxiosRequestConfig {
        const {authState} = authStore;

        return {
            headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${authState.accessToken}`
            },
            data: data,
        };
    }

}

export const apiService = new ApiService();
