import { IDrawProps } from "core/draws";
import { drawStore } from "stores/DrawStore";
import { Feature } from "geojson";
import { IFeatures } from "stores/AreaStore";

export class DrawService {

    public setDrawProps(drawProps: IDrawProps) {
        drawStore.setDrawProps(drawProps)
    }

    public setDrawMode(drawMode: string) {
        drawStore.setDrawMode(drawMode)
    }

    public setAreaToSave(feature: Feature) {
        drawStore.setAreaToSave(feature)
    }

    public removeAreaToSave(id: string) {
        drawStore.removeAreaToSave(id)
    }

    public clearAreaToSave() {
        drawStore.clearAreaToSave()
    }

    public setDrawData(features: IFeatures) {
        drawStore.setDrawData(features)
    }

    public removeFeatureFromDrawData(id: string) {
        drawStore.removeFeatureFromDrawData(id)
    }

    public setAllIds(allIds: string[] | undefined) {
        drawStore.setAllIds(allIds)
    }

}

export const drawService = new DrawService();
