import { localStorageService } from "services";

class StrongholdsController {

    public getLocalStorageAreaVisibleById(id: number) {
        return localStorageService.getLocalStorageAreaVisibleById(id)
    }
}

export const strongholdsController = new StrongholdsController();
