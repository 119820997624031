import React, { useCallback } from "react";
import { patreonService } from "services";
import { Button } from "primereact/button";

export const NavLinkLayout = () => {
    const ssoPatreon = patreonService.getAuthPatreonRedirectUrl();
    const onPatreon = useCallback(() => {
        window.location.href = ssoPatreon;
    }, [ssoPatreon]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #ccc',
            padding: '20px',
            borderRadius: '8px',
            width: '100%',
            textAlign: 'center',
            boxSizing: 'border-box'
        }}>
            <p style={{ marginBottom: '20px', fontSize: '16px' }}>
                To watch the video, please log in using your Patreon account.
            </p>
            <Button
                label="Login with Patreon"
                icon="pi pi-patreon"
                onClick={onPatreon}
                style={{
                    height: '50px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 'none',
                    color: 'white',
                    fontSize: '16px'
                }}
            >
                <img
                    src="/social/patreon.png"
                    alt="patreon.png"
                    style={{ height: '30px', marginLeft: '10px' }}
                />
            </Button>
        </div>
    );
};
