import React, { Dispatch, SetStateAction } from "react";
import 'primeicons/primeicons.css';
import { Button } from "primereact/button";
import styles from './RefreshButton.module.scss';

interface RefreshButtonProps {
    isSpinning: boolean;
    className: string;
    refreshData: () => void;
    setIsSpinning: Dispatch<SetStateAction<boolean>>;
}

export const RefreshButton = React.memo(({isSpinning, className, refreshData}: RefreshButtonProps) => {

    return (
        <div className={className}>
            <Button
                icon="pi pi-refresh"
                className={`p-button-rounded ${isSpinning ? styles.spin : ''}`}
                onClick={refreshData}
                aria-label="Refresh Data"
            />
        </div>
    )
})
