export const routes = {
  main: {
    path: '/map',
  },
  login: {
    path: '/login',
  },
  patreon: {
    path: '/auth/patreon/'
  },
  signUp: {
    path: '/signUp',
  },
  enterCode: {
    path: '/enterCode',
  }
};
