import { makeAutoObservable, runInAction } from "mobx";
import { IDrawWithId } from "services/AreaService";

export class SnapShotsStore {
    snapShots: IDrawWithId[]
    constructor() {
        makeAutoObservable(this)
        this.snapShots = [];
    }

    public setSnapShots(snapShots: IDrawWithId[]) {
        runInAction(() => this.snapShots = snapShots);
    }

    public resetSnapShots() {
        runInAction(() => this.snapShots = []);
    }
}

export const snapShotsStore = new SnapShotsStore();
