import React, { Dispatch, SetStateAction, useCallback, useContext } from "react";
import { modalStore } from "stores";
import { ModalType } from "core/modals";
import RightContext from "contexts/AppRightsProvider";
import { getRights } from "../../../utils";
import { RIGHTS_TYPES } from "../../../utils/getRights";

interface AccountInfoButtonProps {
    className: string;
    setSideBarVisible?: Dispatch<SetStateAction<boolean>>;
}

export const AccountInfoButton = React.memo(({className, setSideBarVisible}: AccountInfoButtonProps) => {
    const {rights} = useContext(RightContext);
    const handleOnAccountButtonClick = useCallback(() => {
        modalStore.open({
            type: ModalType.AccountInfo,
            actionType: undefined,
        });
        setSideBarVisible?.(false);
    }, [setSideBarVisible]);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleOnAccountButtonClick();
        }
    }, [handleOnAccountButtonClick]);

    if (getRights(rights, RIGHTS_TYPES.SIGN_IN)) return null

    return <div className={className}>
        <img
            src='images/channel.png'
            alt='Channel'
            role="button"
            onClick={handleOnAccountButtonClick}
            onKeyDown={handleKeyDown}
            tabIndex={0}
        />
    </div>
});
