import { makeAutoObservable, runInAction } from "mobx";
import { IDrawProps } from "core/draws";
import { Feature } from "geojson";
import { IFeatures } from "./AreaStore";

export const DEFAULT_DRAW_PROPS: IDrawProps = {
    arrowMode: false,
    distanceMode: false,
    defaultMode: false,
    editMapMode: false,
}

export type IDrawData = {
    type: 'FeatureCollection';
    features: IFeatures
}

export const DEFAULT_DRAW_MODE: string = 'static'

export class DrawStore {
    drawData: IDrawData;
    drawProps: IDrawProps;
    drawMode: string;
    areaToSave: Feature | undefined;
    allIds: string[] | undefined ;

    constructor() {
        makeAutoObservable(this);
        this.drawProps = DEFAULT_DRAW_PROPS;
        this.drawMode = DEFAULT_DRAW_MODE;
        this.drawData = {
            type: 'FeatureCollection',
            features: []
        };
        this.allIds = undefined;
    }

    public setDrawProps(drawProps: IDrawProps) {
        runInAction(() => {
            this.drawProps = drawProps;
        })
    }

    public setDrawMode(drawMode: string) {
        runInAction(() => {
            this.drawMode = drawMode;
        })
    }

    public setAreaToSave(feature: Feature) {
        runInAction(() => {
            this.areaToSave = feature;
        })
    }

    public removeAreaToSave(id: string) {
        runInAction(() => {
            if (this.areaToSave && id === this.areaToSave.id) return this.areaToSave = undefined
            return
        })
    }

    public clearAreaToSave() {
        runInAction(() => {
            this.areaToSave = undefined
        })
    }

    public setDrawData(featureCollection: IFeatures) {
        runInAction(() => {
            this.drawData.features = featureCollection
        })
    }

    public removeFeatureFromDrawData(id: string) {
        runInAction(() => {
            this.drawData.features = this.drawData.features.filter(feature => feature.id !== id)
        })
    }

    public setAllIds(allIds: string[] | undefined) {
        runInAction(() => this.allIds = allIds)
    }
}


export const drawStore = new DrawStore();
