import { ApiConfig, apiService } from "./ApiService";
import { meStore } from "stores/MeStore";
import { IMe } from "core/types";

export class MeService {

    public async getMeRequest(accessToken: string) {
        const me = meStore.getMe();
        if (!me) return await apiService.axiosGetRequest(ApiConfig.ME, accessToken).then(res => {
            this.setMeToStore(res.data)
        }).catch(() => this.setMeToStore(null))
    }
    public setMeToStore(me: IMe | null) {
        meStore.setMe(me)
    }
}

export const meService = new MeService();
