import React, { createElement, memo } from "react";
import { observer } from "mobx-react";
import { IColor, UnitTypes } from "core/types";
import cn from "clsx";
import { getImageUrl, MARKER_ICON_TYPES } from "utils";
import styles from "../Marker.module.scss";
import { FOLDERS } from "utils/getImageUrl";

interface IRenderMarkerProps {
    id: number,
    icon: string | UnitTypes,
    markerTypeTitle: string,
    titleNative: string,
    color: IColor,
    isMove: boolean,
}

export const RenderMarker = memo(observer(({
                                               id,
                                               icon,
                                               markerTypeTitle,
                                               titleNative,
                                               color,
                                               isMove = false
                                           }: IRenderMarkerProps) => {

    const createImageClasses = cn([
        styles.image,
        !isMove && styles.image_hover
    ])

    const getIconUrl = (): string => {
        if (markerTypeTitle === 'Warrior' || markerTypeTitle === 'Leader') {
            return getImageUrl({
                countryCode: titleNative,
                icon,
                folder: FOLDERS.PERSONS,
            }).url
        }
        return icon
    }

    const component = MARKER_ICON_TYPES.find((type) => type.type === markerTypeTitle)?.component
    if (component) return createElement(component, {
        id,
        icon: getIconUrl(),
        markerTypeTitle,
        titleNative,
        color,
        imageClasses: createImageClasses,
        withFlag: true,
    })
    return <></>
}))

export const Wagner = () => (
    <img className={styles.images_special} src={`images/units/wagner.png`} alt='images/units/wagner.png'/>)

export const Akhmat = () => (
    <img className={styles.images_special} src={`images/units/akhmat.png`} alt='images/units/akhmat.png'/>)

