import { useContext, useEffect } from "react";
import RightContext from "contexts/AppRightsProvider";
import { getRights } from "utils";
import { RIGHTS_TYPES } from "utils/getRights";

export const AdSense = () => {
    const {rights} = useContext(RightContext);

    useEffect(() => {
        const existingScript = document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3361594636064418"]');

        // Если скрипт еще не добавлен, добавляем его один раз
        if (!existingScript) {
            const adsenseScript = document.createElement('script');
            adsenseScript.async = true;
            adsenseScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3361594636064418';
            adsenseScript.setAttribute('crossorigin', 'anonymous');
            document.head.appendChild(adsenseScript);
        }

        // Добавляем или показываем рекламный блок
        const adBlock = document.querySelector('.adsbygoogle') as HTMLElement;
        // Просто изменяем видимость уже существующего рекламного блока
        if (adBlock) adBlock.style.visibility = getRights(rights, RIGHTS_TYPES.HIDE_GOOGLE_ADSENSE) ? 'hidden' : 'visible';
    }, [rights]);

    return null;
};
