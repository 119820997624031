import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo } from "react";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Button } from "primereact/button";
import { Nullable } from 'primereact/ts-helpers';
import { calendarComponentController } from "./CalendarComponent.controller";
import { getRights } from "utils";
import { RIGHTS_TYPES } from "utils/getRights";
import RightContext from "contexts/AppRightsProvider";

interface ICalendarComponentProps {
    dates: Nullable<Date[]>,
    className: string,
    isSpinning: boolean,
    setDates: Dispatch<SetStateAction<Nullable<Date[]>>>,
    isRangeSelected: boolean,
}

export const CalendarComponent = React.memo(({
                                                 dates,
                                                 className,
                                                 isSpinning,
                                                 setDates,
                                                 isRangeSelected
                                             }: ICalendarComponentProps) => {

    const {rights} = useContext(RightContext);

    const handleOnDateChange = useCallback((e: CalendarChangeEvent) => {
        const date = e.value;
        if (Array.isArray(date)) setDates(date);
    }, [setDates]);

    const adjustDate = useCallback((days: number) => {
        if (dates && dates.length > 0) {
            const adjustedDates = dates.map(date => {
                const newDate = new Date(date);
                newDate.setDate(newDate.getDate() + days);
                return newDate;
            });
            setDates(adjustedDates);
        }
    }, [dates, setDates]);

    const isToday = useCallback((date: Date) => {
        const today = new Date();

        return (date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear())
    }, []);

    const isNextDisabled = useMemo(() => {
        return dates ? dates.some(date => date ? isToday(date) : false) : false;
    }, [dates, isToday]);

    // Кнопки отключены только если выбран настоящий интервал, а не одна дата
    const isPrevDisabled = useMemo(() => isSpinning || (isRangeSelected && dates?.length === 2), [isSpinning, isRangeSelected, dates?.length]);
    const isNextButtonDisabled = useMemo(() => isNextDisabled || isPrevDisabled, [isPrevDisabled, isNextDisabled]);

    useEffect(() => {
        calendarComponentController.setIsToday(isNextDisabled)
    }, [isNextDisabled])

    return (
        <div className={className} style={{display: 'flex', alignItems: 'center'}}>
            {getRights(rights, RIGHTS_TYPES.CHANGE_DATE) &&
              <div style={{display: 'flex', gap: '8px', marginRight: '10px'}}>
                  {/* Кнопка для уменьшения даты на один день */}
                <Button
                  icon="pi pi-chevron-left"
                  onClick={() => adjustDate(-1)}
                  disabled={isPrevDisabled}
                  className="p-button-rounded"
                />

                  {/* Кнопка для увеличения даты на один день */}
                <Button
                  icon="pi pi-chevron-right"
                  onClick={() => adjustDate(1)}
                  disabled={isNextButtonDisabled}
                  className="p-button-rounded"
                />
              </div>}

            {/* Компонент календаря */}
            <Calendar
                value={dates}
                onChange={handleOnDateChange}
                selectionMode="range"
                readOnlyInput
                disabled={isSpinning || !getRights(rights, RIGHTS_TYPES.CHANGE_DATE)}
            />
        </div>
    );
});
