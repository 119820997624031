import { routes } from 'core/navigation';
import { Formik } from 'formik';
import { useAppAuth } from 'hooks/useAppAuth';
import { observer } from 'mobx-react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useCallback, useContext } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { authService, meService, patreonService } from 'services';
import { authStore } from 'stores';
import RightContext from "../../contexts/AppRightsProvider";
import { RIGHTS_NAMES } from "../../utils/getRights";

export interface FormValues {
    email: string;
    password: string;
}


export interface FormErrors {
    email?: string;
    password?: string;
}


export const SignIn = observer(() => {
    const {authState} = useAppAuth();
    const {isLoading} = authStore;
    const {rights} = useContext(RightContext);

    const ssoPatreon = patreonService.getAuthPatreonRedirectUrl();
    const ssoGoogle = patreonService.getAuthGoogleRedirectUrl();


    const onGoogle = useCallback(() => {
        window.location.href = ssoGoogle;
    }, [ssoGoogle]);


    const onPatreon = useCallback(() => {
        window.location.href = ssoPatreon;
    }, [ssoPatreon]);

    const validate = (values: FormValues): FormErrors => {
        const errors: FormErrors = {};
        if (!values.email) {
            errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        return errors;
    };


    if (authState.isAuthenticated && rights !== RIGHTS_NAMES.ANONYMOUS) {
        return <Navigate to={routes.main.path}/>;
    }


    return (
        <div className="auth">
            <div className="auth-logo">
                <div className="auth-logo-title"> Military Summary</div>

                <div className="auth-logo-image">
                    <img src="/images/channel.png" alt="channel.png"/>
                </div>
            </div>

            <div className="auth-content">
                {isLoading ? <ProgressSpinner/> :
                    <>
                        <Formik
                            initialValues={{email: '', password: ''}}
                            validate={validate}
                            onSubmit={(values, {setSubmitting, resetForm}) => {
                                authService.logIn({...values}).then((res) => {
                                    authService.setAuthState(res.data);
                                    meService.getMeRequest(res.data.accessToken);
                                    setSubmitting(false);
                                    resetForm();
                                    return;
                                }).catch(() => {
                                    setSubmitting(true);
                                    resetForm();
                                    return;
                                });
                            }}
                        >
                            {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                                <form className="auth-form" onSubmit={handleSubmit}>
                                    <div className="auth-form-title">Sign In</div>

                                    <div className="auth-form-field">
                                        <div className="auth-form-field-label">Enter your email</div>
                                        <div className="auth-form-field-control">
                                            <InputText
                                                type="email"
                                                name="email"
                                                id="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                                autoComplete="on"
                                            />
                                        </div>
                                    </div>

                                    <div className="auth-form-field">
                                        <div className="auth-form-field-label">Enter your password</div>
                                        <div className="auth-form-field-control">
                                            <InputText
                                                type="password"
                                                name="password"
                                                id="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                                autoComplete="on"
                                            />
                                            {errors.password && touched.password && errors.password}
                                        </div>
                                    </div>

                                    <div className="auth-form-actions">
                                        <Button label="Submit" type="submit" disabled={isSubmitting} severity="success"
                                                raised/>
                                    </div>
                                </form>
                            )}
                        </Formik>
                        <div className="auth-form-or">Or:</div>
                        <div className="auth-social" style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <Button
                                label="Login with Patreon"
                                icon="pi pi-patreon"
                                onClick={onPatreon}
                                style={{
                                    height: '50px',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <img src="/social/patreon.png" alt="patreon.png"
                                     style={{height: '30px', marginLeft: '10px'}}/>
                            </Button>

                            <Button
                                label="Login with Google"
                                icon="pi pi-google"
                                onClick={onGoogle}
                                style={{
                                    height: '50px',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <img src="/social/google.svg" alt="google.png"
                                     style={{height: '30px', marginLeft: '10px'}}/>
                            </Button>
                        </div>
                    </>}
                <div className="auth-form-or">Or:</div>
                <NavLink to={routes.main.path}>
                    <Button
                        label="Back To map"
                        style={{
                            height: '50px',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img src="/images/ms.png" alt="ms.png"
                             style={{height: '30px', marginLeft: '10px'}}/>
                    </Button>
                </NavLink>
            </div>

            <div className="auth-info">
                <h2 className="auth-info-title">Important!:</h2>

                <p>You need to have a <span
                    style={{color: '#16b24f', fontWeight: 600, fontSize: 18}}>5$</span> <b>Patreon</b> subscription
                    to get <b>ADS FREE</b> access to the map</p>

                <a style={{fontWeight: 600}} href="https://www.patreon.com/militarySummary?fan_landing=true"
                   target="_blank"
                   rel="noopener noreferrer">
                    https://www.patreon.com/militarySummary?fan_landing=true
                </a>

                <p className="auth-info-subtitle">If you have a subscription, then you need to follow the next
                    steps:</p>

                <ol>
                    <li><b>Step 1:</b> You need to visit militarysummary.com</li>
                    <li><b>Step 2:</b> There you should press Login with Patreon</li>
                    <li><b>Step 3:</b> This will redirect you to the Patreon permission Page</li>
                    <li><b>Step 4:</b> On this page, you need to press the Allow button</li>
                    <li><b>Step 5:</b> You will be redirected to the map after step 4</li>
                </ol>
                <br/>
                <p><strong>IMPORTANT NOTE:</strong> You should verify your email in the Patreon system.</p>
                <br/>
                <br/>
            </div>
        </div>
    );
});
