import React, { Dispatch, SetStateAction, useCallback, useMemo, useState, memo, useContext } from "react";
import cn from "clsx";
// @ts-ignore
import TelegramEmbed from 'react-telegram-embed';
import { TwitterTweetEmbed } from "react-twitter-embed";
import RightContext from "contexts/AppRightsProvider";
import { modalStore } from "stores";
import { actionTypes, ModalType } from "core/modals";
import { IMarker, IMarkerType } from "core/types";
import { popupTypes, PopupTypes } from "core/popups";
import { getMarkerTypeInfo, getRights } from "utils";
import { RIGHTS_TYPES } from "utils/getRights";
import { RmpComponent } from "components";
import { Image } from 'primereact/image';
import { PopupLayout } from "./PopupLayout/PopaupLayout";
import { DEVICE_TYPES, DeviceType } from "hooks/useDeviceType";
import { mapActionClickType } from "components/Modals/CreateMarkerModal/CreateMarkerModal";
import { NavLinkLayout } from "./NavLinkLayout/NavLinkLayout";
import styles from './Popup.module.scss'

interface IPopupProps {
    marker: IMarker;
    setPopupInfo: Dispatch<SetStateAction<IMarker | undefined>>;
    deviceType: DeviceType;
    markerTypesData: IMarkerType[];
    setIsMoveMarker: Dispatch<SetStateAction<boolean>>;
    setMarkerData: Dispatch<SetStateAction<IMarker | undefined>>;
    setIsCopyMarkerToCoordinates: Dispatch<SetStateAction<boolean>>;
}

export const Popup = memo(({
                               marker,
                               setPopupInfo,
                               deviceType,
                               markerTypesData,
                               setIsMoveMarker,
                               setMarkerData,
                               setIsCopyMarkerToCoordinates,
                           }: IPopupProps) => {

    const {rights} = useContext(RightContext);
    const [isPlayButtonClicked, setIsPlayButtonCLicked] = useState<boolean>(false);

    const handleOnEditClick = useCallback(() => {
        modalStore.open({
            type: ModalType.EditMarker,
            markerData: marker,
            onClose: () => setPopupInfo(undefined),
            actionType: mapActionClickType.EDIT_MARKER as actionTypes
        })
    }, [marker, setPopupInfo])

    const handleOnCopyClick = useCallback(() => {
        modalStore.open({
            type: ModalType.CreateMarker,
            markerData: marker,
            onClose: () => setPopupInfo(undefined),
            actionType: mapActionClickType.COPY_MARKER as actionTypes,
        })
    }, [marker, setPopupInfo])

    const handleOnMoveClick = useCallback(() => {
        setMarkerData(marker)
        setPopupInfo(undefined)
        setIsMoveMarker(true)
    }, [setIsMoveMarker, setPopupInfo, marker, setMarkerData])

    const handleOnCopyToClick = useCallback(() => {
        setMarkerData(marker)
        setPopupInfo(undefined)
        setIsCopyMarkerToCoordinates(true)
    }, [marker, setMarkerData, setIsCopyMarkerToCoordinates, setPopupInfo])

    const handleOnCloseClick = useCallback(() => {
        setPopupInfo(undefined)
    }, [setPopupInfo])

    const handleOnPlayClick = useCallback(() => {
        setIsPlayButtonCLicked(true)
    }, [setIsPlayButtonCLicked])

    const getBadgeTitle: string = useMemo(() => {
        return markerTypesData?.filter(typeData => typeData.id === marker.type)[0].title ?? ''
    }, [markerTypesData, marker])

    const markerType = useMemo(() => {
        return getMarkerTypeInfo(marker.type)
    }, [marker])

    const telegramPopupClasses = useMemo(() => cn([
        markerType?.title === 'unit' ? styles.telegramContainer_unit : styles.telegramContainer_event
    ]), [markerType?.title])

    const getSizes = useMemo(() => {
        if (deviceType === DEVICE_TYPES.MOBILE) return {
            width: '450',
            height: '255'
        }
        return {
            width: '900',
            height: '505'
        }
    }, [deviceType])

    const getUrl = useCallback(() => {
        return `${marker.videoUrl?.substring(marker.videoUrl?.indexOf('https'), marker.videoUrl?.indexOf('akv') + 3)}&rel=0&autoplay=2`
    }, [marker.videoUrl])

    const popupType: PopupTypes = useMemo(() => {
        if ((markerType?.title === "Leader" || markerType?.title === "Warrior") && !isPlayButtonClicked) return popupTypes.LEADER
        if (marker.videoUrl?.includes('cloudfront.net')) return popupTypes.RMP
        if (marker.videoUrl?.includes('rumble')) return popupTypes.RUMBLE
        if (marker.image?.includes('cdn.militarysummary.com') && markerType?.title === "unit") return popupTypes.UNIT
        if (marker.videoUrl?.includes('t.me')) return popupTypes.TELEGRAM
        if (marker.videoUrl?.includes('twitter')) return popupTypes.X
        if (marker.desc || marker.title) return popupTypes.DESCRIPTION
        return popupTypes.DEFAULT
    }, [isPlayButtonClicked, marker.desc, marker.title, marker.videoUrl, markerType?.title, marker.image])

    const popupLayout = useMemo(() => {

        const leaderImageUrl = marker.image.includes('cdn.militarysummary.com') ? marker.image : `images/presidents/${marker.image}.jpg`;
        const warriorImageUrl = marker.image.includes('cdn.militarysummary.com') ? marker.image : `images/warriors/${marker.image}.jpg`;
        if (popupType === popupTypes.LEADER) return (
            <div className={styles.contentLayout}>
                <div className={styles.imageLayout}>
                    {markerType?.title === "Leader" && <img src={leaderImageUrl} alt=''/>}
                    {markerType?.title === "Warrior" && <img src={warriorImageUrl} alt=''/>}
                    <div className={styles.imageBadge}>{getBadgeTitle}</div>
                </div>
                <div className={styles.content}>
                    <div className={styles.title}>{marker.title}</div>
                    <div className={styles.desc}>{marker.desc}</div>
                </div>
            </div>
        )

        if (popupType === popupTypes.RMP) return (<>
                {
                    getRights(rights, RIGHTS_TYPES.SHOW_VIDEO) ? (
                        <>
                            <RmpComponent
                                videoUrl={marker.videoUrl}
                                sizes={getSizes}
                            />
                            {marker.desc && <div className={styles.desc_rumble}>{marker.desc}</div>}
                        </>) : <NavLinkLayout/>
                }</>
        )


        if (popupType === popupTypes.RUMBLE) return (<>
                {
                    getRights(rights, RIGHTS_TYPES.SHOW_VIDEO) ? (
                            <>
                                <iframe title="rumblePlayer" className="rumble" width={getSizes.width}
                                        height={getSizes.height}
                                        src={getUrl()}
                                        allowFullScreen/>
                                {marker.desc && <div className={styles.desc_rumble}>{marker.desc}</div>}</>) :
                        <NavLinkLayout/>
                }</>
        )

        if (popupType === popupTypes.TELEGRAM) return (
            <>
                <div className={styles.content_title}>{marker.title}</div>
                <div className={styles.telegramEmbed}>
                    <TelegramEmbed src={marker.videoUrl}/>
                </div>
            </>
        )

        if (popupType === popupTypes.X) return (
            <TwitterTweetEmbed
                tweetId={marker.videoUrl?.includes('twitter') ? marker.videoUrl?.replace('twitter', '') : ''}
                onLoad={function noRefCheck() {
                }}
            />
        )

        if (popupType === popupTypes.UNIT) return (
            <Image src={marker.image} alt="Image" width="250" />
        )

        if (popupType === popupTypes.DESCRIPTION) return (
            <div className={styles.content_desc}>
                {marker.title && <div className={styles.title}>{marker.title}</div>}
                {marker.desc && <div className={styles.desc}>{marker.desc}</div>}
            </div>
        )

        return null
    }, [marker, popupType, getBadgeTitle, getSizes, getUrl, markerType?.title, rights])

    const popupStyles = useMemo(() => {
        if (popupType === popupTypes.LEADER) return styles.popupLayout
        if (popupType === popupTypes.RMP) return styles.radiantPlayer
        if (popupType === popupTypes.RUMBLE) return ''
        if (popupType === popupTypes.UNIT) return styles.unit
        if (popupType === popupTypes.TELEGRAM) return telegramPopupClasses

        return styles.telegramContainer_event
    }, [popupType, telegramPopupClasses])

    return (<PopupLayout
        className={popupStyles}
        rights={rights}
        handleOnCloseClick={handleOnCloseClick}
        handleOnPlayClick={popupType === popupTypes.LEADER && marker.videoUrl ? handleOnPlayClick : undefined}
        handleOnEditClick={handleOnEditClick}
        handleOnCopyClick={popupType !== popupTypes.LEADER ? handleOnCopyClick : undefined}
        handleOnMoveClick={popupType !== popupTypes.LEADER ? handleOnMoveClick : undefined}
        handleOnCopyToClick={popupType !== popupTypes.LEADER ? handleOnCopyToClick : undefined}
    >
        {popupLayout}
    </PopupLayout>)
})
