import { mapService } from "services";

export class CalendarComponentController {

    public setIsToday(isToday: boolean): boolean {
        mapService.setIsToday(isToday)
        return isToday
    }
}

export const calendarComponentController = new CalendarComponentController();
