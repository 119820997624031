import { IEventType } from "core/types";

export const EVENTS_TYPES: IEventType[] = [
    {
        type: 'explosion',
        value: 'explosion',
        name: 'Explosion',
    }, {

        type: 'attention',
        value: 'attention',
        name: 'Attention',
    }, {

        type: 'airDefense',
        value: 'airDefense',
        name: 'Air Defense',
    }, {

        type: 'shelling',
        value: 'shelling',
        name: 'Shelling',
    }, {
        type: 'fight',
        value: 'fight',
        name: 'Fight',
    }, {
        type: 'flag',
        value: 'flag',
        name: 'Flag',
    },
    {
        type: 'casualties',
        value: 'casualties',
        name: 'Casualties',
    }, {
        type: 'stop',
        value: 'stop',
        name: 'Stop',
    }, {
        type: 'electricity',
        value: 'electricity',
        name: 'Electricity',
    }, {
        type: 'camp',
        value: 'camp',
        name: 'Camp',
    }, {
        type: 'fire',
        value: 'fire',
        name: 'Fire',
    }, {
        type: 'manPads',
        value: 'manPads',
        name: 'ManPads',
    }, {
        type: 'train',
        value: 'train',
        name: 'Train',
    }, {
        type: 'microphone',
        value: 'microphone',
        name: 'Microphone',
    }, {
        type: 'plane',
        value: 'plane',
        name: 'Plane',
    }, {
        type: 'mines',
        value: 'mines',
        name: 'Mines',
    }, {
        type: 'missile',
        value: 'missile',
        name: 'Missile',
    }, {
        type: 'chemical',
        value: 'chemical',
        name: 'Chemical',
    }, {
        type: 'arrest',
        value: 'arrest',
        name: 'Arrest',
    }, {
        type: 'drone',
        value: 'drone',
        name: 'Drone',
    }, {
        type: 'car',
        value: 'car',
        name: 'Car',
    }, {
        type: 'bus',
        value: 'bus',
        name: 'Bus',
    }, {
        type: 'ship',
        value: 'ship',
        name: 'Ship',
    }, {
        type: 'terrorist',
        value: 'terrorist',
        name: 'Terrorist',
    }, {
        type: 'health',
        value: 'health',
        name: 'Health',
    }, {
        type: 'signal',
        value: 'signal',
        name: 'Signal',
    }, {
        type: 'picture',
        value: 'picture',
        name: 'Picture',
    }, {
        type: 'authority',
        value: 'authority',
        name: 'Authority',
    },{
        type: 'geran',
        value: 'geran',
        name: 'Geran',
    },{
        type: 'cruiseMissile',
        value: 'cruiseMissile',
        name: 'Cruise Missile',
    },{
        type: 'tank',
        value: 'tank',
        name: 'Tank',
    },{
        type: 'missileLaunch',
        value: 'missileLaunch',
        name: 'Missile Launch',
    },{
        type: 'fpvdrone',
        value: 'fpvdrone',
        name: 'FPV Drone',
    },
]
