import { mapStore } from "stores";

export class MapService {

    public async loadImage(path: string, type: string, isSdf: boolean) {
        const mapInstance = mapStore.mapInstance
        if (mapInstance) {
            if (!mapInstance.hasImage(type)) {
                await mapInstance.loadImage(path, async (error: any, image: any) => {
                    if (error) return this.loadImage('/events/attention.png', type, isSdf);
                    return mapInstance.addImage(type, image, {sdf: isSdf});
                });
            }
        }
    }

    public setLoadedMapInstance(loadedMapInstance: any) {
        mapStore.setLoadedMapInstance(loadedMapInstance)
    }

    public setIsToday(isToday: boolean) {
        mapStore.setIsToday(isToday)
    }
}

export const mapService = new MapService();
