const getEventsIconPath = (folder: folders) => `/dynamic/map/icons/${folder}/`;

export enum sizes {
    SIZE_128 = '@128x128.png',
    SIZE_64 = '@64x64.png',
    SIZE_32 = '@32x32.png',
}

export const SIZES = {
    SIZE_128: sizes.SIZE_128,
    SIZE_64: sizes.SIZE_64,
    SIZE_32: sizes.SIZE_32,
};

export enum folders {
    EVENTS = 'events',
    PERSONS = 'persons'
}

export const FOLDERS = {
    EVENTS: folders.EVENTS,
    PERSONS: folders.PERSONS
}

interface IImgUrlProps {
    countryCode: string,
    icon: string,
    size?: sizes,
    folder: folders,
}

export const getImageUrl = ({
                                countryCode,
                                icon,
                                size = SIZES.SIZE_128,
                                folder = FOLDERS.EVENTS,
                            }: IImgUrlProps) => {
    const url = `${getEventsIconPath(folder)}${countryCode}-${icon}${size}`;
    const type = `${countryCode}-${icon}${size}`;
    return {
        url,
        type
    };
}
