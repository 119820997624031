import React, { Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import RightContext from 'contexts/AppRightsProvider';
import { tableService } from 'services';
import { mapStore } from 'stores';
import { TableType } from 'core/tables';
import { IMarker } from 'core/types';
import { IDrawProps } from 'core/draws';
import { getRights } from 'utils';
import { RIGHTS_TYPES } from "utils/getRights";
import {
    Notifications,
    CoordinatesButton,
    ControlButtons,
    SideBarLayout,
    PointToast, AreasSideBarLayout,
    CalendarRefreshComponent, TablesSideBarLayout,
} from './index';
import { SignInNavLink } from "components/SignInNavLink/SignInNavLink";
import useDeviceType from 'hooks/useDeviceType';
import styles from './PageLayout.module.scss';

interface IPageLayoutProps {
    coordinatesPoint: { lat: number, lng: number } | null;
    setCoordinatesPoint: Dispatch<SetStateAction<{ lat: number, lng: number } | null>>;
    typeIds: number[];
    eventsMarkerTypeId: number | undefined;
    isCreateMarker: boolean;
    onMarkerClick: (marker: IMarker) => void;
    handleOnModeChangeClicked: (mode: string, drawProperties: IDrawProps | undefined) => void;
    createMarker: () => void;
}


export const PageLayout: React.FC<PropsWithChildren<IPageLayoutProps>> = observer((
    {
        children,
        coordinatesPoint,
        setCoordinatesPoint,
        typeIds,
        eventsMarkerTypeId,
        isCreateMarker,
        onMarkerClick,
        handleOnModeChangeClicked,
        createMarker,
    }) => {

    const deviceType = useDeviceType();

    const [isPointCoordinatesEnabled, setIsPointCoordinatesEnabled] = useState<boolean>(false);

    const {zoom} = mapStore;
    const {rights} = useContext(RightContext);

    const openTable = (tableType: TableType) => {
        tableService.openTable(tableType);
    };

    return (
        <div>
            {children}

            {getRights(rights, RIGHTS_TYPES.SIGN_IN) && <SignInNavLink
              classname={styles.signInNavLink}
            />}
            {getRights(rights, RIGHTS_TYPES.TABLES_BUTTON) && <TablesSideBarLayout
              onClick={openTable}
            />}
            <ControlButtons
                deviceType={deviceType}
                createMarker={createMarker}
                handleOnModeChangeClicked={handleOnModeChangeClicked}
                isCreateMarker={isCreateMarker}
                layoutClassName={styles.buttonsLayout}
                isPointCoordinatesEnabled={isPointCoordinatesEnabled}
                setIsPointCoordinatesEnabled={setIsPointCoordinatesEnabled}
            />
            {getRights(rights, RIGHTS_TYPES.COORDINATES_BUTTON) && <CoordinatesButton
              className={styles.coordinatesInput}
            />}
            <Notifications/>
            {!getRights(rights, RIGHTS_TYPES.SIGN_IN) && <SideBarLayout
              accountInfoClassName={styles.mainImage}
              onMarkerClick={onMarkerClick}
              deviceType={deviceType}
            />}
            {!getRights(rights, RIGHTS_TYPES.SIGN_IN) && <AreasSideBarLayout
              accountInfoClassName={styles.mainImage}
              deviceType={deviceType}
            />}
            {eventsMarkerTypeId && !getRights(rights, RIGHTS_TYPES.SIGN_IN) && <CalendarRefreshComponent
              calendarClassName={styles.calendar}
              refreshButtonClassName={styles.refreshButton}
              eventsMarkerTypeId={eventsMarkerTypeId}
              typeIds={typeIds}
            />}
            <PointToast
                coordinatesPoint={coordinatesPoint}
                isPointCoordinatesEnabled={isPointCoordinatesEnabled}
                zoom={Number(zoom.toFixed(1))}
                setCoordinatesPoint={setCoordinatesPoint}
            />
        </div>
    );
});
