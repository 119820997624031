import React from "react";
import { UnitTypes } from "core/types";
import { getImageUrl } from "utils";
import { folders, sizes, FOLDERS, SIZES } from "utils/getImageUrl";

export interface IEventsProps {
    id?: number
    icon: string | UnitTypes,
    imageClasses?: string,
    titleNative: string,
    size?: sizes,
    folder?: folders
}

export const Events = React.memo(({
                                      icon,
                                      titleNative,
                                      imageClasses,
                                      size = SIZES.SIZE_64,
                                      folder = FOLDERS.EVENTS
                                  }: IEventsProps) => {
    const imageUrl = getImageUrl({countryCode: titleNative, icon, size, folder})
    return <img className={imageClasses} src={imageUrl.url} alt={imageUrl.type}/>
})
