import { areaService } from "services";
import { IDrawWithId } from "services/AreaService";

class TerritoryLayersController {

    public setTerritories(areas: IDrawWithId[]) {
        areaService.setTerritories(areas)
    }
}

export const territoryLayersController = new TerritoryLayersController();
