import React, { Dispatch, SetStateAction, useContext, useMemo } from "react";
import { observer } from "mobx-react";
import { ModeButton } from "components/ModeButton/ModeButton";
import RightContext from "contexts/AppRightsProvider";
import { IDrawButtonTypes } from "core/types";
import { getRights } from "utils";
import { RIGHTS_NAMES, RIGHTS_TYPES } from "utils/getRights";
import { DEFAULT_DRAW_PROPS, drawStore } from "stores/DrawStore";
import { IDrawProps } from "core/draws";
import { DEVICE_TYPES, DeviceType } from "hooks/useDeviceType";
import { ColorsButtons } from "../ColorsButtons/ColorsButtons";
import { mapStore, modalStore } from "stores";
import { ModalType } from "core/modals";
import { areaActionTypes } from "components/Modals/CreateModal/CreateModal";

interface IButton {
    icon?: string;
    isModeEnabled: boolean;
    onClick: () => void;
    type: IDrawButtonTypes;
    getText?: () => string;
}

interface DrawButtonsProps {
    layoutClassName: string;
    isCreateMarker: boolean;
    deviceType: DeviceType;
    handleOnModeChangeClicked: (mode: string, drawProperties: IDrawProps | undefined) => void;
    createMarker: () => void;
    isPointCoordinatesEnabled: boolean;
    setIsPointCoordinatesEnabled: Dispatch<SetStateAction<boolean>>;

}

export const ControlButtons = observer(({
                                            layoutClassName,
                                            isCreateMarker,
                                            deviceType,
                                            handleOnModeChangeClicked,
                                            createMarker,
                                            isPointCoordinatesEnabled,
                                            setIsPointCoordinatesEnabled,
                                        }: DrawButtonsProps) => {

    const {rights} = useContext(RightContext);
    const {drawProps, drawMode, areaToSave} = drawStore;
    const {isToday} = mapStore;

    const showColorButton = useMemo(() => rights === RIGHTS_NAMES.ADMIN || rights === RIGHTS_NAMES.CREATOR || rights === RIGHTS_NAMES.PRO, [rights])

    const drawButtons: IButton[] = useMemo(() => {
        const basicButtons = [
            {
                getText: () => drawMode === 'static' ? 'S' : 'A',
                isModeEnabled: drawMode !== 'static',
                onClick: () => handleOnModeChangeClicked('static', DEFAULT_DRAW_PROPS),
                type: 'static'
            }, {
                icon: 'system/paint.png',
                isModeEnabled: drawMode === 'draw_paint_mode',
                onClick: () => handleOnModeChangeClicked('draw_paint_mode', DEFAULT_DRAW_PROPS),
                type: 'paint',
            }, {
                icon: 'system/square.png',
                isModeEnabled: drawMode === 'draw_polygon',
                onClick: () => handleOnModeChangeClicked('draw_polygon', DEFAULT_DRAW_PROPS),
                type: 'polygon'
            }, {
                icon: 'arrowDirection.png',
                isModeEnabled: drawProps.arrowMode,
                onClick: () => handleOnModeChangeClicked('draw_line_string', {
                    arrowMode: !drawProps.arrowMode,
                    distanceMode: false,
                    defaultMode: false,
                    editMapMode: false,
                }),
                type: 'arrow'
            }, {
                icon: 'system/distance.png',
                isModeEnabled: drawProps.distanceMode,
                onClick: () => handleOnModeChangeClicked('draw_line_string', {
                    arrowMode: false,
                    distanceMode: !drawProps.distanceMode,
                    defaultMode: false,
                    editMapMode: false,
                }),
                type: 'distance'
            }, {
                icon: 'system/line.png',
                isModeEnabled: drawProps.defaultMode,
                onClick: () => handleOnModeChangeClicked('draw_line_string', {
                    arrowMode: false,
                    distanceMode: false,
                    defaultMode: !drawProps.defaultMode,
                    editMapMode: false,
                }),
                type: 'line'
            },
            {
                icon: 'system/point.png',
                isModeEnabled: isPointCoordinatesEnabled,
                onClick: () => setIsPointCoordinatesEnabled(!isPointCoordinatesEnabled),
                type: 'pont'
            }

        ]

        const creatorButtons = [
            {
                icon: 'system/marker.png',
                isModeEnabled: isCreateMarker,
                onClick: () => {
                    createMarker();
                    handleOnModeChangeClicked('simple_select', DEFAULT_DRAW_PROPS);
                },
                type: 'create',
            },
        ]

        if (rights === RIGHTS_NAMES.ADMIN) {
            if (deviceType === DEVICE_TYPES.DESKTOP) return [...creatorButtons, ...basicButtons] as IButton[]
            return [...creatorButtons] as IButton[]
        }

        if (rights === RIGHTS_NAMES.CREATOR) {
            if (deviceType === DEVICE_TYPES.DESKTOP) return [...creatorButtons, ...basicButtons] as IButton[]
            return []
        }

        if (rights === RIGHTS_NAMES.PRO) {
            return basicButtons as IButton[]
        }
        return []

    }, [
        rights,
        createMarker,
        isCreateMarker,
        handleOnModeChangeClicked,
        drawMode,
        drawProps.arrowMode,
        drawProps.distanceMode,
        drawProps.defaultMode,
        deviceType,
        isPointCoordinatesEnabled,
        setIsPointCoordinatesEnabled
    ])

    const handleOnSaveAreaClick = () => {
        modalStore.open(
            {
                type: ModalType.AddFeatureToArea,
                actionType: areaActionTypes.ADD_AREA,
            }
        )
    }

    return <div className={layoutClassName}>
        {isToday && areaToSave && getRights(rights, RIGHTS_TYPES.CHANGE_MAP) && <ModeButton
          isModeEnabled={true}
          icon='system/save.png'
          disabled={false}
          onClick={handleOnSaveAreaClick}
        />}
        {deviceType === DEVICE_TYPES.DESKTOP && showColorButton && <ColorsButtons
          handleOnModeChangeClicked={handleOnModeChangeClicked}
        />}
        {
            drawButtons.map((button: IButton, index: number) => {
                return <ModeButton
                    key={index}
                    icon={button.icon}
                    isModeEnabled={button.isModeEnabled}
                    disabled={button.type !== 'create' ? isCreateMarker : false}
                    getText={button.getText}
                    onClick={button.onClick}/>
            })
        }
    </div>
})
