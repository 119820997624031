import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Slider } from 'primereact/slider';
import { InputText } from 'primereact/inputtext';

interface RmpComponentProps {
    videoUrl?: string;
    sizes?: {
        width: string;
        height: string;
    };
}

export interface IRadiantMP {
    on(event: string, callback: () => void): void;

    off(event: string, callback: () => void): void;

    init(settings: any): void;

    setPlaybackRate(rate: number): void;

    destroy(): void;
}

export const RmpComponent = React.memo(({
                                            videoUrl = 'video/militarySummary.mp4',
                                            sizes = {
                                                width: '640',
                                                height: '360',
                                            },
                                        }: RmpComponentProps) => {
    const [playbackRate, setPlaybackRate] = useState(1);
    const rmpInstanceRef = useRef<IRadiantMP | null>(null);

    const src = useMemo(() => {
        if (videoUrl?.includes('mp4')) return {mp4: [videoUrl]}
        return {hls: [videoUrl]}
    }, [videoUrl])

    const settings = useMemo(() => ({
        licenseKey: process.env.REACT_APP_RADIAN_PLAYER_LICENSE_KEY,
        src: src,
        width: parseInt(sizes.width),
        height: parseInt(sizes.height),
        ads: false,
    }), [src, sizes.width, sizes.height]);

    useEffect(() => {
        if (!rmpInstanceRef.current) {
            rmpInstanceRef.current = new window.RadiantMP('rmp');
        }

        const rmpInstance = rmpInstanceRef.current;
        rmpInstance.init(settings);

        return () => {
            rmpInstance.destroy();
            rmpInstanceRef.current = null;
        };

    }, [settings]);

    useEffect(() => {
        if (rmpInstanceRef.current) {
            const rmpInstance = rmpInstanceRef.current;
            rmpInstance.setPlaybackRate(playbackRate);
        }

    }, [playbackRate]);

    return (
        <div>
            <div id="rmp"/>
            <InputText
                value={`Speed: ${playbackRate.toFixed(1)}x`}
                style={{marginTop: '10px'}}
                readOnly
            />
            <Slider
                value={playbackRate}
                onChange={(e) => {
                    if (typeof e.value === 'number') setPlaybackRate(e.value);
                }}
                min={0.5}
                max={3}
                step={0.1}
                style={{width: '100%', marginTop: '10px'}}
            />
        </div>
    );
});
