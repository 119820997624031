import { makeAutoObservable, runInAction } from "mobx";
import { Feature, GeoJSON, GeoJsonProperties, Geometry } from 'geojson';
import { IDraw, IDrawWithId } from "services/AreaService";
import { localStorageService } from "services";

export type IFeatures = IFeature[];
export type IFeature = Feature<Geometry, GeoJsonProperties | GeoJSONWithId<GeoJSON>>;

interface IProperties {
    color?: string,
    areaId?: number,
    content?: string[]
}

export type GeoJSONWithId<T> = Partial<T>
    & {
    id?: number | string,
    properties?: IProperties
}

export class AreaStore {

    areas: IDrawWithId[];
    territories: Feature<Geometry, GeoJsonProperties>[]
    editAreaId: number | undefined;

    constructor() {
        makeAutoObservable(this)
        this.areas = [];
        this.territories = [];
        this.editAreaId = undefined;
    }

    public setAreas(areas: IDraw[]) {
        runInAction(() => {
            this.areas = areas
        })
    }

    public addAreaToStore(area: IDrawWithId) {
        runInAction(() => {
            this.areas.push(area);
        })
    }

    public removeAreaFromStore(areaId: number) {
        runInAction(() => {
            this.areas = this.areas.filter(areaWithId => areaWithId.id !== areaId);
        })
    }

    public setEditAreaId(id: number | undefined) {
        runInAction(() => {
            this.editAreaId = id
        })
    }

    public updateAreaInStore(area: IDrawWithId) {
        runInAction(() => {
            this.areas = this.areas.map(drawWithId => drawWithId.id === area.id ? area : drawWithId)
            const object = this.areas.find(drawWithId => drawWithId.id === area.id);
            if (object) {
                object.title = area.title;
                object.desc = area.desc;
            }
        })
    }

    public setAreaVisible(isVisible: boolean, id: number) {
        runInAction(() => {
            this.areas = this.areas.map(area => {
                if (area.id !== id) return area;
                if (area.geoJson.type !== 'Point') area.geoJson = {
                    ...area.geoJson,
                    features: area.geoJson.features.map(feature => {
                        if (feature.type === "Feature" && feature.geometry.type !== 'Point' && feature.properties) {
                            return {...feature, properties: {...feature.properties, isVisible: isVisible}};
                        }
                        return feature;
                    }) as Feature[]
                };
                return area;
            });
        });
    }

    public setTerritories(snapShots: IDrawWithId[]) {
        runInAction(() => {
            const updateArray = (array1: IDrawWithId[], array2: IDrawWithId[]) => {
                // Создаем объект для быстрого поиска объектов второго массива по id
                const array2Map = new Map(array2.map(item => [item.id, item]));

                // Заменяем объекты в первом массиве, если их id совпадает с id объектов второго массива
                return array1.map(item => array2Map.get(item.id) || item);
            };

            const updatedArray = updateArray(this.areas, snapShots);

            this.territories = updatedArray.flatMap(area =>
                area.geoJson.features.map(feature => {
                    // Ensure each feature has an id in properties
                    feature.properties = feature.properties || {};
                    feature.properties.id = feature.properties.id || feature.id || `${area.id}-${feature.geometry.type}-${Math.random()}`;
                    if (area.id) feature.properties.isVisible = localStorageService.getLocalStorageAreaVisibleById(area.id)

                    return feature;
                })
            ).filter((feature): feature is Feature<Geometry> =>
                feature.type === "Feature" &&
                feature.geometry.type !== 'LineString' &&
                feature.geometry.type !== 'MultiLineString'
            )
        })
    }
}

export const areaStore = new AreaStore();
