import { IFeatures } from "stores/AreaStore";
import { areaService } from "services";
import { drawService } from "services/DrawService";
import { IDrawProps } from "core/draws";
import { Feature } from "geojson";
import { drawStore } from "stores";


export class DrawComponentController {

    public initAreas() {
        areaService.initAreas()
    }

    public setDrawData(features: IFeatures) {
        drawService.setDrawData(features)
    }

    public setDrawProps(drawProps: IDrawProps) {
        drawService.setDrawProps(drawProps)
    }

    public setDrawMode(drawMode: string) {
        drawService.setDrawMode(drawMode)
    }

    public setAreaToSave(feature: Feature) {
        drawService.setAreaToSave(feature)
    }

    public clearAreaToSave() {
        drawService.clearAreaToSave()
    }

    public removeAreaToSave(id: string) {
        drawService.removeAreaToSave(id)
    }

    public removeAreasFromDrawData() {
        const {drawData, allIds} = drawStore;
        if (allIds) {
            drawService.setDrawData(drawData.features.filter(feature => {
                if (typeof feature.id === "string") return !allIds.includes(feature.id);
                return [];
            }))
        }
        drawService.setAllIds(undefined);

    }
}

export const drawComponentController = new DrawComponentController();
