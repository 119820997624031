import { Nullable } from "primereact/ts-helpers";
import moment from "moment";
import { drawService, markersService, snapShotService } from "services";
import { drawStore } from "../../../stores";

export class CalendarRefreshComponentController {

    public init(typeIds: number[]) {
        return markersService.init(typeIds)
    }

    public getEvents(dates: Nullable<Date[]>, eventsMarkerTypeId: number | undefined) {
        if (!dates || !eventsMarkerTypeId) return;

        const formattedDates = dates.map((date, index) => {
            if (index === 0) return moment(date).startOf('day').toISOString()
            if (index === 1) return moment(date).endOf('day').toISOString()
            return moment(date).startOf('day').toISOString()
        })
        const [createdFrom, createdTo] = formattedDates

        return markersService.getEvents({
            typeIds: [eventsMarkerTypeId],
            dates: {
                createdFrom: createdFrom,
                createdTo: createdTo,
            }
        });
    }

    public getSnapShots(dates: Nullable<Date[]>) {
        if (!dates) return;

        const formattedDates = dates.map((date, index) => {
            if (index === 0) return moment(date).startOf('day').format('YYYY-MM-DD')
            if (index === 1) return moment(date).endOf('day').format('YYYY-MM-DD')
            return moment(date).startOf('day').toISOString()
        })
        const [createdFrom, createdTo] = formattedDates
        snapShotService.getSnapShots(createdFrom, createdTo)
    }

    public resetSnapShots() {
        snapShotService.resetSnapShots()
    }

    public removeAreasFromDrawData(ids: string[]) {
        const {drawData} = drawStore;
        drawService.setDrawData(drawData.features.filter(feature => {
            if (typeof feature.id === "string") return !ids.includes(feature.id)
            return []
        }))
    }
}

export const calendarRefreshComponentController = new CalendarRefreshComponentController();
