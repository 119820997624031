import { markersService, notificationService } from "services";
import { IFeatures } from "stores/AreaStore";
import { UNIT_TYPES} from "utils";
import { TableType } from "core/tables";
import { IMarker } from "core/types";
import { IDrawProps } from "core/draws";
import { mapStore, markersStore, notificationStore, tableStore } from "stores";
import { drawService, mapService } from "services";

export const EVENT_TYPE_TITLE = "Events"

export class MapGlContainerController {

    getEventsMarkerTypeId() {
        const {markerTypesData} = tableStore
        if (markerTypesData) return markerTypesData.find(type => type.title === EVENT_TYPE_TITLE)?.id
        return undefined
    }

    getTypeIds(eventsMarkerTypeId: number | undefined) {
        return tableStore.markerTypesData.map(markerType => markerType.id).filter(id => id !== eventsMarkerTypeId)
    }

    async onMapLoaded(e: { target: any, type: string }) {
        mapService.setLoadedMapInstance(e.target)

        await mapService.loadImage(`arrowDirection.png`, 'arrowDirection', true);

        UNIT_TYPES.map(async (unit) => {
            if (unit.hasImage) await mapService.loadImage(`units/${unit.type}.png`, unit.type, false);
        })
    }

    getMarkersByTypeIds(typeIds: number[]) {
        const {markers} = markersStore;
        if (typeIds && typeIds.length > 0 && markers.length === 0) markersService.init(typeIds);
    }

    handleOnMouseMove(event: any) {
        mapStore.onMapMouseMove(event)
    }

    async updateMarker(markerData: IMarker) {
        const {mapProps} = mapStore;
        markersStore.removeMarkerFromStore([markerData.id])
        markersService.updateMarkerAPIRequest({
            id: markerData.id,
            title: markerData.title,
            type: markerData.type,
            country: markerData.country,
            desc: markerData.desc,
            icon: markerData.icon,
            image: markerData.image,
            videoUrl: markerData.videoUrl,
            coordinates: {
                lat: mapProps.lngLat.lat,
                lng: mapProps.lngLat.lng
            },
        }).then(res => {
            if (res) {
                markersStore.addMarkerToStore({
                    ...res.data,
                });
                notificationStore.push(notificationService.create({
                    data: res.data,
                    dataType: TableType.MarkersTable,
                    type: 'success',
                    actionType: 'update'
                }));
            }
        });
    }

    async copyMarkerToCoordinates(markerData: IMarker) {
        const {mapProps} = mapStore;
        await markersService.createMarkerAPIRequest({
            title: markerData.title,
            type: markerData.type,
            country: markerData.country,
            desc: markerData.desc,
            icon: markerData.icon,
            image: markerData.image,
            videoUrl: markerData.videoUrl,
            coordinates: {
                lat: mapProps.lngLat.lat,
                lng: mapProps.lngLat.lng
            },
        })
    }

    public setDrawData(features: IFeatures) {
        drawService.setDrawData(features)
    }

    public setDrawProps(drawProps: IDrawProps) {
        drawService.setDrawProps(drawProps)
    }

    public setDrawMode(drawMode: string) {
        drawService.setDrawMode(drawMode)
    }

    public setMarkerCoordinates(coordinates: [number, number] | undefined) {
        mapStore.setMarkerCoordinates(coordinates)
    }
}

export const mapGlContainerController = new MapGlContainerController();
