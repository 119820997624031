export class PatreonService {

    public getAuthPatreonRedirectUrl(): string {
        return process.env.REACT_APP_PATREON_CALLBACL_LINK || ''
    }

    public getAuthGoogleRedirectUrl(): string {
        return process.env.REACT_APP_GOOGLE_CALLBACL_LINK || ''
    }

}

export const patreonService = new PatreonService();
